import './App.css';
import { HashRouter as Router } from "react-router-dom";
import BaseRouter from "./Config/BaseRouter";
import React, { useState, useEffect } from 'react';
import { APICALL } from "./Services/ApiServices";
import {  GetDeviceDetails } from "./Services/ApiEndPoints";
import Logo from "./Pages/Logo/logo";
import * as allDevices from 'react-device-detect';
import LoadingIcon from "./Utilities/LoadingIcon";


function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [accessDenied, setAccessDenied] = useState('');

  if (document.getElementById("loading-icon") !== null) {
    document.getElementById("loading-icon").style.display = "none";
    // .setAttribute("style", "display:none;");
  }
  useEffect(() => {
    let data = {
      'data': allDevices
    }
    console.log(allDevices);
    APICALL.service(GetDeviceDetails, "POST" , data).then((result) => {
             console.log(result.DATA);
             if(result.DATA === false){
              setAccessDenied('Access denied')
             }
           
            userHasAuthenticated(result.DATA)
    });
     //Getting translation and storing in the localstorage.. 
    // APICALL.service(DataTranslations, "GET").then((result) => {
    //   if (result !== null) {
    //     localStorage.setItem("translations", JSON.stringify(result));
    //   }
    // });
    
  }, []);


  return (
<div className="App">

  {isAuthenticated ?
  <Router basename={process.env.REACT_APP_ROUTER_BASE || ""}>
  <BaseRouter></BaseRouter>
  </Router> : 
  <div>
    {accessDenied !== ''?<div>
    <Logo />
    <div className="col-md-12 col-lg-12 col-sm-12 with_out">
    <h3>Access Denied </h3>
    </div>
</div>:''}
  </div>
  }
<LoadingIcon />
</div>
  );
}

export default App;
