import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Delivery from './Delivery';
import GetRedirectionUrl from "../../Utilities/GetRedirectionUrl"
import { useParams } from "react-router";

const ParentDelivery = () => {
    console.log("ParentDelivery");
  const [showDelivery, setShowDelivery] = useState(true);
  const history = useHistory();
  let params = useParams();
  console.log(params);
//   let data = {
//     'locationId': locationId,
//     'type_id': params.type_id,
//     'language': lang,
//     'screen_id' : params.screen_id
//   }

  const nextFunction = () => {
    setShowDelivery(false)
    // Perform any logic you need before navigating to the next page
    console.log("ParentDelivery next");
    let next_url = '/common/screen/'+ params.screen_id + '/' + params.type_id
    // var page = "common"
    // var response = GetRedirectionUrl(page)
    console.log(next_url);
    // Set showDelivery to false to unmount the Delivery component
    setShowDelivery(true);

    // Navigate to the next page using history.push
    // history.push(next_url);
    history.push({
            pathname: next_url
    });
  };

  return (
    <div>
      {showDelivery && <Delivery nextFunction={nextFunction} />}
    </div>
  );
};

export default ParentDelivery;
