import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./withoutAppointment.css";
import {
  WithoutAppUrl,
  ContractorUrl,
  VisitorTypeUrl,
} from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import { useHistory } from "react-router-dom";
import Header from "../Header/header";
import GetRedirectionUrl from "../../Utilities/GetRedirectionUrl";
import { t } from "../../Translation/Translation";
import { useParams } from "react-router";
import Logo from "../Logo/logo";

const WithoutAppointment = (props) => {
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const params = useParams();
  const [without, setWitout] = useState({
    name: null,
    address: null,
    company: null,
    checked: false
  });
  const [checked, setChecked] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },reset
  } = useForm({
    defaultValues: without
    });
  var path;

  // if (params.from !== undefined) {
  //   path = params.from;
  // }

  if(localStorage.getItem("screen_key") && localStorage.getItem("screen_key") !== null) 
  {
     path = JSON.parse(localStorage.getItem("screen_key"));
  }

  console.log(params);
  const [allDetails, setAllDetails] = useState();
  if (params.from !== undefined) {
    var from_url = params.from;
    var pageNo = Number(params.page);
  }
  useEffect(() => {
    if (
      localStorage.getItem("location") &&
      localStorage.getItem("location") !== null
    ) {
      var location = JSON.parse(localStorage.getItem("location"));
      let locationId = location["value"];
      let lang = localStorage.getItem("activeLanguage");
      let data = {
        locationId: locationId,
        language: lang,
      };
      APICALL.service(VisitorTypeUrl, "POST", data).then((result) => {
        if (result) {
          setAllDetails(result["DATA"]);
        }
      });
    } else {
      history.push({
        pathname: "/select-location",
      });
    }
  }, []);
  var page = "visitorDetails";
  var response = GetRedirectionUrl(page);
  console.log("ww");
  console.log(response);
  /**
   * fetch saved data and set the all fields for update flow.
   */
  useEffect(() => {
    if (params.id !== undefined && params.id !== "undefined") {
      let lang = localStorage.getItem("activeLanguage");

      let data = {
        entry_id: params.id,
        flow: "edit",
        language: lang,
      };
      APICALL.service(WithoutAppUrl, "POST", data)
        .then((result) => {
            console.log(result);
          if (result.user_details !== undefined) {
            setWitout(result.user_details);
            setChecked(result.condition);
            reset(result.user_details);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [reset]);

  /**
 * save the data to backend after submitting the form for both contractor and without appointment flow
  @param {} data the field values of the form
 */
  const onSubmit = (data) => {
    setDisabled(true);
    var final_location = "";
    if (
      localStorage.getItem("location") &&
      localStorage.getItem("location") !== null
    ) {
      let location2 = JSON.parse(localStorage.getItem("location"));
      final_location = location2["value"];
    }
    var details = [];
    details[0] = data;
    if (path === "contractor") {
      let user_data = {
        visitor_flow:'contractor',
        user_type: path,
        visitor: details,
        company: data["company"],
        location: final_location,
        type_id: params.type_id,
        screen_id : params.screen_id,
        entry_id:
          params.type_id !== undefined && params.type_id !== null ? params.type_id : "",
        flow: "save",
      };

      APICALL.service(ContractorUrl, "POST", user_data)
        .then((result) => {
          setDisabled(false);
          if (result.hostname !== undefined && result.hostname !== null) {
            localStorage.setItem("hostname", JSON.stringify(result.hostname));
          }
          let array = allDetails;
          let index = pageNo;
          let newItem = {
            screenType: "visitorDetails",
            screenDetails: result["entry_id"],
          };

          let newItem1 = { screenType: "delivery" };

          var data1 = [
            ...array.slice(0, index),
            newItem,
            ...array.slice(index),
          ];
          let data2 = [
            ...data1.slice(0, index + 1),
            newItem1,
            ...data1.slice(index + 1),
          ];
          localStorage.setItem("details", JSON.stringify(data2));
          // history.push({
          //   pathname: "/delivery/" + (pageNo + 1) + "/" + from_url + "/",
          // });
          history.push({
            pathname: response.next,
          });
        })
        .catch((error) => {
          setDisabled(false);
          console.error(error);
        });
    } else {
      // console.log("ddddd");return;

      
      let user_data = {
        visitor_flow:'without appointment',
        user_type: path,
        location: final_location,
        visitor: details,
        company: data["company"],
        type_id: params.type_id,
        screen_id : params.screen_id,
        entry_id:
          params.type_id !== undefined && params.type_id !== null ? params.type_id : "",
        flow: "save",
      };
      APICALL.service(WithoutAppUrl, "POST", user_data)
        .then((result) => {
          setDisabled(false);
          if (result.hostname !== undefined && result.hostname !== null) {
            localStorage.setItem("hostname", JSON.stringify(result.hostname));
          }
          // let array = allDetails;
          // let index = pageNo;
          // let newItem1 = {
          //   screenType: "visitorDetails",
          //   screenDetails: result["entry_id"],
          // };

          // let data3 = [
          //   ...array.slice(0, index),
          //   newItem1,
          //   ...array.slice(index),
          // ];
          // localStorage.setItem("details", JSON.stringify(data3));
          console.log("without appointment 195");
          console.log(response);
          history.push({
            pathname: response.next,
          });
        })
        .catch((error) => {
          setDisabled(false);
          console.error(error);
        });
    }
  };

  /**
     * onchange function of all fields to set the field value
      @param {} event 
     */
  // const handleChange = (event) => {
  //   let { name, value } = event.target;
  //   const newValues = { ...without, [name]: value };
  //   setWitout(newValues);
  // };

  /**
   * onclick function for checkbox to set the checkbox value
   */
  const handleClick = () => setChecked(!checked);

  return (
    <div className="bgcolor m-auto col-lg-12 container-fluid p-0">
      <Logo />
      <div className="col-12 with_out pb-3">
        <Header heading={t("Visitor details")} />
        <form
          id="appointment-form"
          autocomplete="off"
          className="App"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col xs={6} className="m-auto">
              <Row className="eachbox mb-3">
                <Col lg="3" className="text-left align_item p-0 label_height">
                  <label className="label_own three_zero">
                    {t("Company")}
                    {path === "contractor" ? (
                      <span className="star">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                </Col>
                {path === "contractor" ? (
                  <Col lg="9">
                    <input
                      name="company"
                      type="text"
                      className="input_box"
                      {...register("company", {
                        required: true,
                        pattern: /^[0-9a-zA-ZÀ-ÿ\u00A1-\uFFFF .'-]+$/,
                      })}
                    />
                    {errors.company?.type === "required" && (
                      <span className="error_msg" role="alert">
                        {t("Company name is required")}
                      </span>
                    )}
                    {errors.company?.type === "pattern" && (
                      <span className="error_msg" role="alert">
                        {t("Please enter valid company name")}
                      </span>
                    )}
                  </Col>
                ) : (
                  <Col lg="9">
                    <input
                      name="company"
                      type="text"
                      className="input_box"
                      {...register("company", {
                        pattern: /^[0-9a-zA-ZÀ-ÿ\u00A1-\uFFFF .'-]+$/,
                      })}
                    />
                    {errors.company?.type === "pattern" && (
                      <span className="error_msg" role="alert">
                        {t("Please enter valid company name")}
                      </span>
                    )}
                  </Col>
                )}
              </Row>
              <Row className="eachbox mb-3">
                <Col lg="3" className="text-left align_item p-0 label_height">
                  <label className="label_own three_zero">
                    {t("Email")}
                    {path !== "contractor" ? (
                      <span className="star">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                </Col>
                {path !== "contractor" ? (
                  <Col lg="9">
                    <input
                      name="address"
                      type="email"
                      placeholder={t("example@gmail.com")}
                      className="input_box"
                      {...register("address", {
                        required: true,
                        pattern:
                          // /^([a-zA-Z0-9_\-\.\+]+\@([a-zA-Z-]+[\.]{1}[a-zA-Z]+))$/,
                          // /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})/,
                          /^([a-zA-Z0-9_\-\.\+]+\@([a-zA-Z0-9-]+[\.]{1}[a-zA-Z]+))$/,
                      })}
                    />
                    {errors.address?.type === "required" && (
                      <span className="error_msg" role="alert">
                        {t("Please enter your email")}
                      </span>
                    )}
                    {errors.address?.type === "pattern" && (
                      <span className="error_msg" role="alert">
                        {t("Please enter valid email")}
                      </span>
                    )}
                  </Col>
                ) : (
                  <Col lg="9">
                    <input
                      name="address"
                      type="email"
                      placeholder={t("example@gmail.com")}
                      className="input_box"
                      {...register("address", {
                        pattern:
                          // /^([a-zA-Z0-9_\-\.\+]+\@([a-zA-Z-]+[\.]{1}[a-zA-Z]+))$/,
                          /^([a-zA-Z0-9_\-\.\+]+\@([a-zA-Z0-9-]+[\.]{1}[a-zA-Z]+))$/,
                      })}
                    />
                    {errors.address?.type === "pattern" && (
                      <span className="error_msg" role="alert">
                        {t("Please enter valid email")}
                      </span>
                    )}
                  </Col>
                )}
              </Row>

              <Row className="eachbox mb-3">
                <Col lg="3" className="text-left align_item p-0 label_height">
                  <label className="label_own three_zero">{t("Name")} 
                  <span className="star">*</span>
                  </label>
                  
                </Col>
                <Col lg="9" className="cust_check">
                  <input
                    name="name"
                    type="text"
                    className="input_box"
                    {...register("name", {
                      required: true,
                      pattern: /^[0-9a-zA-ZÀ-ÿ\u00A1-\uFFFF .'-]+$/,
                    })}
                  />
                   {errors.name?.type === "required" && (
                      <span className="error_msg" role="alert">
                        {t("Please enter your name")}
                      </span>
                    )}
                  {errors.name?.type === "pattern" && (
                    <span className="error_msg" role="alert">
                      {t("Please enter valid name")}
                    </span>
                  )}
                </Col>
              </Row>

              <Row className="eachbox">
                <Col lg="3" className="text-left"></Col>
                <Col className="align_self align_item" lg="9">
                  <label class="container_label  label_own2 pl-3 mb-0 ">
                    {t(
                      "I understand that my data will be saved for two weeks for safety reasons"
                    )}
                    <input
                      name="condition"
                      type="checkbox"
                      className="check_height"
                      value={checked}
                      {...register("condition", { required: true })}
                      onClick={handleClick}
                      checked={checked}
                    />
                    <span class="checkmark"></span>
                  </label>
                </Col>
              </Row>
              <div className="col-md-12 col-lg-12 row pt-3">
                <div className="col-md-3 col-lg-3"></div>
                <div className="col-md-9 col-lg-9">
                  {errors.condition?.type === "required" && (
                    <span className="error_msg" role="alert">
                      {t("Please accept the condition to proceed to next page")}
                    </span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </div>
      <Row className="eachbox">
        <Col lg="6" className="eachbox   pr-0 text-left">
        {response['back'] !== undefined && response['back'] !== null ?
            (<button className="sub_button mr-10 back-home-button"
              onClick={() => {
                history.push({
                  pathname: response['back'],
                });
              }}
            >
              {t('Home')}
            </button>) : null
          }
        </Col>
        <Col lg="6" className="align_intial">
          <div className="eachbox sub2">
            <input
              className="sub_button"
              form="appointment-form"
              type="submit"
              value={t("submit")}
              disabled={disabled}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WithoutAppointment;